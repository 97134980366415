<template>
  <v-container  style="text-align: center">
    <v-col class="mb-4">
      <h1 class="display-2 font-weight-bold mb-3">Регистрация</h1>
    </v-col>

    <v-row>
      <v-col cols="4" class="mb-4"></v-col>
      <v-col cols="4" class="mb-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Название вашей компании (как в документах)*"
            required
          ></v-text-field>

          <v-text-field
            v-model="inn"
            :rules="innRules"
            type="number"
            label="ИНН вашей компании*"
            required
          ></v-text-field>

          <v-text-field
            v-model="phone"
            v-mask = "'+#(###) ###-##-##'"
            label="Телефон*"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-text-field
            v-model="pass"
            :rules="passRules"
            label="пароль*"
            required
            type="password"
          ></v-text-field>

          <v-text-field
            v-model="passNext"
            :rules="[passRulesNext]"
            label="повторите пароль*"
            type="password"
            required
          ></v-text-field>

          <v-checkbox
            v-model="checkbox"
            label="Нажимая зарегистрироваться."
            required
          ></v-checkbox>

          <v-btn
            :disabled="!valid"
            color="#e6b006"
            class="mr-4"
            @click="validate"
          >
            Зарегистрироваться
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="4" class="mb-4"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" to="/login"> Войти </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: "",
    inn: "",
    pass: "",
    passNext: "",
    phone: "",
    nameRules: [(v) => !!v || "Название фирмы должно быть заполнено"],
    innRules: [
      (v) => !!v || "inn is должно быть заполнено",
      (v) => (v && (v.length == 10 || v.length == 12)) || "ИНН должнет состоять из 10 или 12 цифр",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passRules: [
      (v) => !!v || "Пароль должен быть заполнен",
      (v) =>
        (v && v.length >= 8) || "Пароль должен состоять от 8 символов и более",
    ],
    passRulesNext: [
      (v) => !!v || "Пароль должен быть заполнен",
      (v) =>
        (v && v.length >= 8) || "Пароль должен состоять от 8 символов и более",
    ],
    select: null,
    checkbox: false,
  }),
  name: "Register",
  methods: {
    validate() {
      this.$refs.form.validate();
      let f = {
        name: this.name,
        inn: this.inn,
        ps: this.pass,
        eml: this.email,
        phone: this.phone,
      };
      this.$store.dispatch("setNewUser", f);
      this.name = "";
      this.inn = "";
      this.email = "";
      this.pass = "";
      this.passNext = "";
      this.phone = "";
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    matchingPasswords() {
      if(this.pass === this.passNext) {
        return "true"
      } else {
        return "Пароли должны совпадать!"
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
