<template>
  <v-container>
    <v-row justify="center" width="100%">
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card>
          <v-card-title class="text-h5">
            {{ titleDlg }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Название"
                    required
                    v-model="data.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="ListMainMenu"
                    label="меню"
                    v-model="data.level"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    truncate-length="15"
                    label="Загрузить pdf (название файла должно быть на латинице без пробелов!)"
                    accept="application/pdf"
                    v-model="files"
                    @change="setFile"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text> Удалить </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Отмена
            </v-btn>
            <v-btn color="green darken-1" text @click="onSave">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="3">
        <menus></menus>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <div class="cabinet" style="text-align: center">
              <h1>файлы</h1>
            </div>
          </v-col>
          <v-col cols="12"
            ><v-btn class="mx-2" fab dark color="indigo" @click="onNew">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn></v-col
          >
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="20"
              class="elevation-1"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menus from "../Menus.vue";

export default {
  name: "states",

  components: {
    Menus,
  },
  data() {
    return {
        files: "",
      dialog: false,
      content: "dddd",
      titleDlg: "Добавление",
      data: {
        id: 0,
        name: "",
        level: "Главное",
        name_file: "",
        link:"",
      },
      headers: [
        {
          text: "Название",
          value: "title",
        },
        { text: "привязка меню", value: "nameMenu" },
        { text: "название файла", value: "description" }
      ],
    };
  },
  computed: {
    ListMainMenu() {
      return this.$store.getters.getListMenuState;
    },
    desserts() {
      return this.$store.getters.getListFiles;
    },
  },
  methods: {
    readData(e) {
      console.log(e);
      this.data.id = e.id;
      this.data.name = e.title;
      this.data.level = e.nameMenu;
      this.data.description = e.description;
      this.dialog = true;
    },
    setFile() {
      if (this.files != null) {
        this.$store.dispatch("setFilesSpec", this.files).then(res=>{
            console.log(res);
            this.data.name_file = res['name'];
            this.data.link = res['link'];
        });
      } 
    },
    onSave() {
      console.log(this.data);
      this.$store.dispatch("setListFiles", this.data);
      this.dialog = false;
    },
    onNew() {
      this.data.id = 0;
      this.data.name = "";
      this.data.level = "";
      this.data.name_file = "";
      this.data.link = "";
      this.dialog = true;
    },
  },
  mounted() {
    this.$store.dispatch("getListMenuFiles");
  },
};
</script>
