<template>
  <v-container>
    <v-row justify="center" width="100%">
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            {{ titleDlg }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="имя компании"
                    required
                    v-model="data.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="ИНН"
                    required
                    v-model="data.inn"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="email"
                    required
                    v-model="data.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="телефон"                    
                    v-mask = "'+#(###) ###-##-##'"
                    required
                    v-model="data.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="listStatus"
                    label="меню"
                    v-model="data.status"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Отмена
            </v-btn>
            <v-btn color="green darken-1" text @click="onSave">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="3">
        <menus></menus>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <div class="cabinet" style="text-align: center">
          <h1>Пользователи</h1>
        </div>
          </v-col>
          <v-col cols="12">
            <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Поиск"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      @click:row="onOpenRead"
    ></v-data-table>
  </v-card>
          </v-col>
        </v-row>        
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menus from "../Menus.vue";
export default {
  name: "Home",

  components: {
    Menus,
  },
  data() {
    return {
      dialog: false,
       titleDlg: "Редактирование",
      data: {
        id: 0,
        name: "",
        inn: "Главное",
        email: "",
        phone: "",
        status: "Пользователь",
      },
      listStatus: ['Пользователь', 'Админ', 'Блок'],
       search: '',
        headers: [
          {
            text: 'Компания',
            value: 'nameCompany',
          },
          { text: 'inn', value: 'inn' },
          { text: 'status', value: 'status' },
          { text: 'email', value: 'email' },
          { text: 'Телефон', value: 'phone' },
        ],
    };
  },
  mounted() {
    this.$store.dispatch("getListUserAdmin");
  },
  computed: {
    desserts() {
      return this.$store.getters.getListUser;
    },     
   },
   methods: {
     onOpenRead(e){
       this.data.id = e.id;
       this.data.name = e.nameCompany;
       this.data.inn = e.inn; 
       this.data.email = e.email;
       this.data.phone = e.phone;
       if(e.status==1) {
         this.data.status = 'Админ';
       }
       if(e.status==2) {
         this.data.status = 'Блок';
       }
       this.dialog = true;
     },
     onSave(){
       let status = 0;
       if(this.data.status == "Админ") {
         status = 1;
       }
       if(this.data.status == "Блок") {
         status = 2;
       }
       let t = {
        id:  this.data.id,
        name: this.data.name,
        st: status,
        inn: this.data.inn,
        email: this.data.email,
        phone: this.data.phone,
       }        
       this.$store.dispatch("setListUser", t);
       this.dialog = false;
     }
   }
};
</script>
