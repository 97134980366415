<template>
  <v-container style="text-align: center">
    <v-col class="mb-4">
      <h1 class="display-2 font-weight-bold mb-3">Авторизация</h1>
    </v-col>

    <v-row>
      <v-col cols="4" class="mb-4"></v-col>
      <v-col cols="4" class="mb-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-text-field
            v-model="pass"
            label="пароль*"
            required
            type="password"
          ></v-text-field>

          <v-btn color="#e6b006" :disabled="!valid" @click="onEnter">
            Войти
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="4" class="mb-4"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" class="mr-4" to="/register">
          Зарегистрироваться
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    pass: "",
    email: "",
    emailRules: [
      (v) => !!v || "E-mail не введен",
      (v) => /.+@.+\..+/.test(v) || "E-mail не правильный",
    ],
    select: null,
    checkbox: false,
  }),
  name: "login",
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    onEnter() {
      console.log(this.email);
      let t  = {
        lg: this.email,
        ps: this.pass,
      }
      this.$store.dispatch("getListMainClient", t).then(res => {
        if(res) {
          console.log(res);
          this.$router.push('/cabinet')
        } else {
          alert("Ошибка авторизации!")
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    isAuthUser() {
      return this.$store.getters.getAuthClient;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
