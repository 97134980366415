import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import adm from './module/admin.js'
import main from './module/main.js'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    onLoad: false,
  },
  modules: {
    adm,
    main,
  },
  mutations: {
    onLoad(state, payload){
      state.onLoad = payload
    }
  },
})

export default store
