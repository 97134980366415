<template>
  <v-card height="500px" width="280px">
    <v-navigation-drawer absolute permanent right>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{nameFirm}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in Items" :key="item.title" :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item key="close" @click="onExit()">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    Items() {
      return this.$store.getters.getMenuUser;
    },
    nameFirm() {
      return this.$store.getters.getNameFirm;
    }
  },
  methods: {
    onExit() {
      this.$store.dispatch("onExitUser").then(res => {
        console.log(res);
        if(res) {
         this.$router.push('/')
        }
      });

    }
  }
};
</script>
