<template>
  <v-container>
    <v-row justify="center" width="100%">
      <v-dialog v-model="dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h5">
            {{ titleDlg }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Название пункта меню"
                    required
                    v-model="data.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="ListMainMenu"
                    label="Принадлежность"
                    v-model="data.level"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Ссылка"
                    required
                    v-model="data.link"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="порядок"
                    required
                    v-model="data.order"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="OnDel">
              Удалить
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Отмена
            </v-btn>
            <v-btn color="green darken-1" text @click="onSave">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="3">
        <menus></menus>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="10">
            <div class="cabinet" style="text-align: center">
              <h1>редактирование меню</h1>
            </div>
          </v-col>
          <v-col cols="2">
            <v-btn class="mx-2" fab dark color="indigo" @click="onNewMenu">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="20"
              @click:row="readData"
              class="elevation-1"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menus from "../Menus.vue";
export default {
  name: "menusAdd",

  components: {
    Menus,
  },
  data() {
    return {
      dialog: false,
      titleDlg: "Добавление пункта меню",
      data: {
        id: 0,
        name: "",
        level: "Главное",
        link: "",
        order: "",
      },
      headers: [
        {
          text: "Меню",
          value: "mainmenu",
        },
        { text: "Уровень", value: "nameMenu" },
        { text: "ссылка", value: "link" },
        { text: "порядок", value: "orderMenu" },
        
      ],
    };
  },
  computed: {
    desserts() {
      return this.$store.getters.getListMenu;
    },
    ListMainMenu() {
      return this.$store.getters.getListMenuEdit;
    },
  },
  mounted() {
    this.$store.dispatch("getListMenu");
  },
  methods: {
    onNewMenu() {
      this.data.id = 0;
      this.data.name = "";
      this.data.link = "";
      this.data.order = 0;
      this.data.level = "Главное"
      this.titleDlg = "Добавление пункта меню";
      this.dialog = true;
    },
    onSave() {    
      this.$store.dispatch("setListMenu", this.data);
      this.dialog = false;
    },
    readData(e) {
      this.titleDlg = "Редактирование меню";
      this.data.id = e.id;
      this.data.name = e.mainmenu;
      this.data.level = e.nameMenu;
      this.data.link = e.link;
      this.data.order = e.orderMenu;
      this.dialog = true;
    },
    OnDel() {
      let t = confirm("Вы уверены что хотет удалить?")
      if (t==true) {
        this.$store.dispatch("delListMenu", this.data.id);
        this.dialog = false;
      }
    }
  },
};
</script>
