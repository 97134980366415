<template>
  <v-app>
    <v-app-bar app color="#e6b006" dark>
      <div class="d-flex align-center">
        <v-btn href="/" text color="#2c1213">
          <h3 class="logo_text" style="color: #2c1213">Trassforstage</h3>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <!-- <v-menu
        v-for="n in items"
        :key="n.mainmenu"
        :attach="$refs.container"
        open-on-hover
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-if="n.submenu != null"
            color="#2c1213"
            v-bind="attrs"
            text
            v-on="on"
          >
            {{ n.mainmenu }}
          </v-btn>
          <v-btn v-else color="#2c1213" v-bind="attrs" text :to="n.link">
            {{ n.mainmenu }}
          </v-btn>
        </template>

        <v-list v-if="n.submenu != null">
          <v-list-item
            v-for="item in n.submenu"
            :key="item.mainmenu"
            link
            router
            :to="{ name: 'states', params: { nameMenu: item.link } }"
            replace
          >
            <v-list-item-title v-text="item.mainmenu"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-spacer></v-spacer>
      <v-menu
        v-for="n in items"
        :key="n.mainmenu"
        :attach="$refs.container"
        open-on-hover
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-if="n.submenu != null"
            color="#2c1213"
            v-bind="attrs"
            text
            v-on="on"
          >
            {{ n.mainmenu }}
          </v-btn>
          <v-btn v-else color="#2c1213" v-bind="attrs" text :to="n.link">
            {{ n.mainmenu }}
          </v-btn>
        </template>
        <v-list v-for="(v, i) in n.submenu" :key="i">
          <v-list-item v-if="v.submenu == undefined" :to="{ name: 'states', params: { nameMenu: v.link } }">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-title>{{ v.mainmenu }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-if="v.submenu != undefined" :value="true" sub-group>
            <template v-slot:activator>
              <v-hover v-slot:default="{ }">
              <v-list-item-title>{{ v.mainmenu }}</v-list-item-title>
              </v-hover>
            </template>
            <v-list-item v-for="(b, j) in v.submenu" :key="j" :to="{ name: 'states', params: { nameMenu: b.link } }">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-title>{{ b.mainmenu }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn v-if="Auth" href="/cabinet" text color="#2c1213">
        <span class="mr-2">В кабинет</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn v-else href="/login" text color="#2c1213">
        <span class="mr-2">Вход</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer dark padless>
      <v-card
        flat
        tile
        class="lighten-1 white--text text-center"
        color="#e6b006"
        width="100%"
      >
        <v-card-text>
          <v-btn
            v-for="icon in contact"
            :key="icon.name_contact"
            class="mx-4 white--text"
            text
            :title="icon.name_contact"
            :href="icon.link + icon.data_contact"
          >
            <v-icon size="24px">
              {{ icon.icon_contact }}
            </v-icon>
            {{ icon.data_contact }}
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-text class="white--text">
          2022 — <strong>Санкт-Петербург</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({

  }),
  computed: {
    Auth() {
      return this.$store.getters.getAuthUser;
    },
    contact() {
      return this.$store.getters.getContact;
    },
    banners() {
      return this.$store.getters.getBannersSite;
    },
  },
  mounted() {
    this.$store.dispatch("getListMenuSite").then((res) => {
      console.log(res);
      this.items = res;
    });
    this.$store.dispatch("getContactSite");
    this.$store.dispatch("getBannersSite");
  },
};
</script>
<style scoped>
.logo_text {
  color: #2c1213;
}
</style>
