<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <menus></menus>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <div class="cabinet" style="text-align: center">
          <h1>Контакты</h1>
        </div>
          </v-col>
          <v-col cols="12">
                  <v-text-field
                    label="Телефон"
                    required
                    v-model="data.phone"
                  ></v-text-field>
          </v-col>
           <v-col cols="12">
                  <v-text-field
                    label="WhatsApp"
                    required
                    v-model="data.whatsApp"
                  ></v-text-field>
          </v-col>
           <v-col cols="12">
                  <v-text-field
                    label="Viber"
                    required
                    v-model="data.viber"
                  ></v-text-field>
          </v-col>
           <v-col cols="12">
                  <v-text-field
                    label="Telegram"
                    required
                    v-model="data.telegram"
                  ></v-text-field>
          </v-col>
           <v-col cols="12">
                  <v-text-field
                    label="Vk"
                    required
                    v-model="data.vk"
                  ></v-text-field>
          </v-col>
           <v-col cols="12">
                  <v-text-field
                    label="Youtybe"
                    required
                    v-model="data.youtybe"
                  ></v-text-field>
          </v-col>
           <v-col cols="12">
                  <v-text-field
                    label="Email"
                    required
                    v-model="data.email"
                  ></v-text-field>
          </v-col>
           <v-col cols="12">
                  <v-text-field
                    label="Адресс"
                    required
                    v-model="data.adress"
                  ></v-text-field>
          </v-col>
           <v-col cols="12">
                  <v-text-field
                    label="Код карты"
                    required
                    v-model="data.maps"
                  ></v-text-field>
          </v-col>
          <v-col cols="12"
            ><v-btn class="mx-2" dark @click="onSaveContact">
              <v-icon dark> Сохранить </v-icon>
            </v-btn></v-col
          >
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menus from "../Menus.vue";
export default {
  name: "Contact",

  components: {
    Menus,
  },
  data() {
    return {
      data: {
        phone: "",
        whatsApp: "",
        viber: "",
        telegram: "",
        email: "",
        vk: "",
        adress: "",
        youtybe: "",
        maps:""
      }
    };
  },
  methods: {
    onSaveContact() {
      this.$store.dispatch("setContact", this.data);
    }
  },
  mounted() {
    this.$store.dispatch("getListContact").then(res=> {
      console.log('ffhfh');
      console.log(res);
      this.data.phone = res.phone;
      this.data.whatsApp = res.whatsapp ;
      this.data.viber = res.viber ;
      this.data.telegram = res.telegram ;
      this.data.vk = res.vk ;
      this.data.youtybe = res.youtybe ;
      this.data.email = res.email ;
      this.data.adress = res.adress ;
      this.data.maps = res.map ;
    });
  },
};
</script>
