<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <menus></menus>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <div class="cabinet" style="text-align: center">
              <h1>Аккаунт</h1>
            </div>
          </v-col>
          <v-col cols="12">
            <v-form ref="form" lazy-validation>
              <v-text-field
                v-model="data.name"
                :rules="nameRules"
                label="Название вашей компании (как в документах)*"
                required
              ></v-text-field>

              <v-text-field
                v-model="data.inn"
                :rules="innRules"
                type="number"
                label="ИНН вашей компании*"
                required
              ></v-text-field>

              <v-text-field
                v-model="data.phone"
                v-mask="'+#(###) ###-##-##'"
                label="Телефон*"
                required
              ></v-text-field>

              <v-text-field
                v-model="data.email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
              <v-btn color="#e6b006" class="mr-4" @click="onSaveDataFirm">
                Сохранить
              </v-btn>
              <v-text-field
                :rules="passRules"
                label="старый пароль*"
                v-model="pswd.oldPass"
                required
                type="password"
              ></v-text-field>

              <v-text-field
                :rules="passRules"
                label="новый пароль*"
                required
                v-model="pswd.newPass"
                type="password"
              ></v-text-field>

              <v-text-field
                :rules="[passRulesNext]"
                label="повторите новый пароль*"
                type="password"
                v-model="pswd.reNewPass"
                required
              ></v-text-field>
              <v-btn color="#e6b006" class="mr-4" @click="UpdatePass">
                Обновить пароль
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menus from "../Menus.vue";
export default {
  name: "account",

  components: {
    Menus,
  },
  data() {
    return {
      data: {
        name: "ss",
        inn: "",
        phone: "",
        email: "",
      },
      pswd: {
          oldPass: "",
          newPass: "",
          reNewPass: "",
      },
      nameRules: [(v) => !!v || "Название фирмы должно быть заполнено"],
    innRules: [
      (v) => !!v || "inn is должно быть заполнено",
      (v) => (v && (v.length == 10 || v.length == 12)) || "ИНН должнет состоять из 10 или 12 цифр",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passRules: [
      (v) => !!v || "Пароль должен быть заполнен",
      (v) =>
        (v && v.length >= 8) || "Пароль должен состоять от 8 символов и более",
    ],
    passRulesNext: [
      (v) => !!v || "Пароль должен быть заполнен",
      (v) =>
        (v && v.length >= 8) || "Пароль должен состоять от 8 символов и более",
    ],
    };
  },
  computed: {
    firm() {
      return this.$store.getters.getFullFirm;
    },
  },
  methods: {
      onSaveDataFirm(){
          this.$store.dispatch("OnSaveFirmData", this.data);
      },
      UpdatePass(){
          if(this.pswd.newPass == this.pswd.reNewPass) {
              this.$store.dispatch("onUpdatePass", this.pswd);
          } else {
              alert('Пароли не совпадают!')
          }
          
      }
  },
  mounted() {
    this.data.name = this.firm.name;
    this.data.inn = this.firm.inn;
    this.data.phone = this.firm.phone;
    this.data.email = this.firm.email;
  },
};
</script>
