<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <menus></menus>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <div class="cabinet" style="text-align: center">
              <h1>Банеры</h1>
            </div>
          </v-col>
          <v-col cols="10">
            <v-file-input
              truncate-length="15"
              label="Загрузить изображение"
              accept="image/png, image/jpeg, image/bmp"
              v-model="files"
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn class="mx-2" dark color="indigo" @click="setFoto">
              Загрузить
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-row v-for="(n, i) in Banners" :key="i">
              <v-col cols="4">
                <img
                  width="70%"
                  :src="n.path_img"
                  alt=""
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Порядок"
                  hide-details="auto"
                  :value="n.order_img"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-checkbox @click="onBlock(n.id)" :value="n.status==1" label="Блокировать"></v-checkbox>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menus from "../Menus.vue";
export default {
  name: "banners",

  components: {
    Menus,
  },
  data() {
    return {
      file: [],
      files: "",
      uploadFoto: false,
    };
  },
  computed: {
    Banners() {
      return this.$store.getters.getListBannerAdm;
    }
  },
  mounted() {
    this.$store.dispatch("getListBannerAdm");
  },
  methods: {
    setFoto() {
      if (this.files != null) {
        this.uploadFoto = true;
        this.$store.dispatch("setFotoSpec", this.files);
      } else {
        this.uploadFoto = false;
      }
    },
    onBlock(t) {
      console.log(t);
      this.$store.dispatch("setBlockFoto", t);
    }
  }
};
</script>
