<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <menus></menus>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <div class="cabinet" style="text-align: center">
              <h1>Корзина</h1>
            </div>
          </v-col>
          <v-col cols="12">
              Ваша корзина пуста
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menus from "../Menus.vue";
export default {
  name: "Home",

  components: {
    Menus,
  },
  data() {
    return {};
  },
};
</script>
