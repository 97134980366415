<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="cabinet" style="text-align: center">
          <h1>{{ title }}</h1>
        </div>
        <div v-html="name"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "States",

  data() {
    return {
      title: "Раздел в разработке",
      name: "",
    };
  },
  beforeRouteUpdate(to, from, next) {
    console.log(to.params.nameMenu);
    this.$store.dispatch("getStateListId", to.params.nameMenu).then((res) => {
      console.log(res);
      if (res.length != 0) {
        if (res[0].type_zn == 0) {
          this.title = res[0].title;
          this.name = res[0].description;
        } else {
          window.open(res[0].description, '_blank');
        }
      } else {
        this.title = "Раздел в разработке";
        this.name = "";
      }
    });
    next();
  },
  beforeRouteEnter(to, from, next) {
    // react to route changes...

    next();
  },
  mounted() {
    console.log("jjj");
    console.log(this.$route.params.nameMenu);
    this.$store
      .dispatch("getStateListId", this.$route.params.nameMenu)
      .then((res) => {
        console.log(res);
        if (res.length != 0) {
          if (res[0].type_zn == 0) {
            this.title = res[0].title;
            this.name = res[0].description;
          } else {
            window.open(res[0].description, '_blank');
          }
        } else {
          this.title = "Раздел в разработке";
          this.name = "";
        }
      });
  },
  computed: {},
};
</script>
