<template>
  <v-container>
    <v-row justify="center" class="dialog5">
      <v-dialog v-model="dialog" persistent max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Новое сообщение
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5"> Поддержка </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form ref="form" lazy-validation>
                  <v-text-field
                    label="Тема сообщения"
                    required
                    v-model="data.title"
                  ></v-text-field>

                  <v-textarea
                    name="input-7-1"
                    label="Текст сообщения"
                    hint="Опише вашу проблему или пожелания"
                    v-model="data.mess"
                  ></v-textarea>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Отмена
            </v-btn>
            <v-btn color="green darken-1" text @click="onSaveMessage">
              Отправить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" class="dialog5">
      <v-dialog v-model="dialog_history" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h5"> Поддержка </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" v-for="(n,i) in hist" :key="i">
                <p><strong>{{n.date_create}}</strong> {{n.description}}</p>
              </v-col>
            </v-row>
            <v-row v-if="data.st!='закрыто'">
              <v-col cols="12">
                <v-form ref="form" lazy-validation>
                  <v-textarea
                    name="input-7-1"
                    label="ответ сообщения"
                    hint="дайти ответ"
                    v-model="data.mess"
                  ></v-textarea>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog_history = false">
              Отмена
            </v-btn>
            <v-btn color="green darken-1" text @click="onSaveMessage">
              Отправить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="3">
        <menus></menus>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                История переписок
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="desserts"
                @click:row="readHistory"
                :search="search"
              ></v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Menus from "../Menus.vue";
export default {
  name: "support",

  components: {
    Menus,
  },
  data() {
    return {
      dialog: false,
      dialog_history: false,
      data: {
        id: 0,
        title: "",
        mess: "",
        st: "",
      },
      hist: [],
      search: "",
      headers: [
        { text: "номер", value: "id" },
        { text: "Тема сообщения", value: "title_sup" },
        { text: "Статус сообщения", value: "name_status" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("getListMessageUser");
  },
  computed: {
    desserts() {
      return this.$store.getters.getUserMessage;
    },
    
  },
  methods: {
    onSaveMessage() {
      this.$store.dispatch(
        "onSaveMessageSupport",
        JSON.parse(JSON.stringify(this.data))
      );
      this.dialog = false;
      this.dialog_history = false;
      this.data.title = "";
      this.data.mess = "";
      this.data.id = 0;
    },
    readHistory(e) {
      console.log(e);
      let t = e.id;
      this.$store.dispatch("getHistoryMessage", t).then(res => {
        if(res != []) {
          console.log(res);
          this.data.id = e.id;
          this.data.title = e.title_sup;
          this.data.st = e.name_status;
          this.hist = res;
          this.dialog_history = true;
        } else {
          alert("Ошибка получения данных!")
        }
      });
    }
  },
};
</script>
<style scoped>
.dialog5 {
  margin-top: 10px;
}
</style>
